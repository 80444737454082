<template>
  <div>
    <title-bar :title="$t('navigations.vendors')"/>
    <div v-if="rows" class="custom-table">
      <advanced-filter
        :fields-array="filterFields"
        :action="showFilterModal"
        @clearAction="showFilterModal = false"
        @paramsChange="setAdvancedParams"
      />
      <table-actions
        :actions="tableActions"
        :addBtnText="$t('messages.vendorCreate')"
        :addBtnLink="$helper.getAddUrl('vendors')"
        :option="filterOption"
        :options="filterOptions"
        :entries="pages"
        @onSearchInput="inputChange"
        @perPageChange="perPageChange"
        @filterChange="showFilterModal = true"
        :defaultPerPage="perPage"
      />
      <table-lite
        :is-slot-mode="true"
        :is-loading="isLoading"
        :columns="columns"
        :rows="rows"
        :sortable="sortable"
        @do-search="setSort"
        @is-finished="isLoading = false"
      >
        <template v-slot:actions="data">
          <div class="just-buttons">
            <edit-btn
                v-if="canUpdate"
              :path="$helper.getEditUrl('vendors', data.value.id)"
            />
            <delete-btn v-if="canDelete" @pressDelete="deleteVendor(data.value['@id'])"/>
          </div>
        </template>
      </table-lite>
      <table-pagination
        v-model="currentPage"
        :total="pages"
        :per-page="perPage"
        @input="onChangePage"
      />
    </div>
  </div>
</template>

<script>

import TitleBar from "@/components/TitleBar.vue";
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import EditBtn from "@/components/Buttons/EditBtn.vue";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";
import ResourceUtils from "@/mixins/ResourceUtils";
import AdvancedFilter from "@/components/Table/AdvancedFilter.vue";

export default {
  name: "AdminHolidayListing",
  components: {
    TableLite,
    TablePagination,
    TableActions,
    EditBtn,
    DeleteBtn,
    TitleBar,
    AdvancedFilter
  },
  mixins: [ResourceUtils],
  data() {
    return {
      search: "",
      isLoading: false,
      rows: null,
      perPage: this.$store.state.defaultPerPage,
      currentPage: 1,
      pages: 0,
      enabled: true,
      showFilterModal: false,
      canUpdate: false,
      canDelete: false,
      tableActions: [ 'search', 'perPage','advancedFilter'],
      filterFields: [
          {
              label: 'forms.name',
              name: "name",
              value: null,
              type: "text",
          },
          {
              label: 'forms.contactName',
              name: "contactName",
              value: null,
              type: "text",
          },
          {
              label: 'forms.telephone',
              name: "telephone",
              value: null,
              type: "text",
          },
          {
              label: 'forms.email',
              name: "email",
              value: null,
              type: "text",
          },
          {
              label: 'forms.contactNameAlt',
              name: "contactNameAlt",
              value: null,
              type: "text",
          },
          {
              label: 'forms.telephoneAlt',
              name: "telephoneAlt",
              value: null,
              type: "text",
          },
          {
              label: 'forms.emailAlt',
              name: "emailAlt",
              value: null,
              type: "text",
          },
          {
              label: 'forms.company',
              name: "company",
              value: null,
              type: "text",
          },
          {
              label: 'forms.website',
              name: "website",
              value: null,
              type: "text",
          },
      ],
      advancedParams: [],
      sortable: {
        order: "familyName",
        sort: "asc",
      },
      columns: [
        {
          label: this.$t("forms.name"),
          field: "name",
          sortable: true,
        },
        {
          label: this.$t("forms.contactName"),
          field: "contactName",
          sortable: true,
        },
        {
          label: this.$t("forms.email"),
          field: "email",
          sortable: true,
        },
        {
          label: this.$t("forms.telephone"),
          field: "telephone",
          sortable: true,
        },
        {
          label: this.$t("forms.website"),
          field: "website",
          sortable: true,
        },
        {
          label: this.$t("forms.actions"),
          field: "actions",
          sortable: false,
          width: "80px",
        },
      ],
    };
  },
  created() {
    this.setPermissions();
    this.load();
  },
  methods: {
    load() {
      const params = {
        itemsPerPage: this.perPage,
        page: this.currentPage,
      };
      this.advancedParams.forEach(element => {
        if (element.value !== '' && element.value !== null) {
          params[element.name] = element.value
        }
      });
      params["order[" + this.sortable.order + "]"] = this.sortable.sort;
      if (this.search && this.search.length) {
        params.search = this.search;
      }
      this.$Vendors.getCollection({params}, 'vendor_list').then((response) => {
        this.pages = response.data["hydra:totalItems"];
        this.rows = response.data["hydra:member"];
      });
    },
    setPermissions() {
      if(this.$helper.userCapability('CREATE', 'VENDORS')) {
        this.tableActions.push('addBtn')
      }
      this.canUpdate = this.$helper.userCapability('UPDATE', 'VENDORS')
      this.canDelete = this.$helper.userCapability('DELETE', 'VENDORS')
    },
    setAdvancedParams(data) {
      this.advancedParams = data
      this.load()
    },
    setSort(offset, limit, order, sort) {
      this.sortable.order = order;
      this.sortable.sort = sort;
      this.load();
    },
    onChangePage(page) {
      this.currentPage = page;
      this.load();
    },
    deleteVendor(url) {
      this.deleteByUrl(
        this.$Users,
        url,
        this.$t("messages.vendorDeleted"),
        null,
        this.load,
        this.error
      );
    },
    inputChange(value) {
      this.currentPage = 1;
      this.search = value;
      this.load();
    },
    onPageChange() {
      this.load();
    },
    perPageChange(num) {
      this.currentPage = 1;
      this.perPage = num;
      this.load();
    },
    filterSelectChange(enabled) {
      this.enabled = enabled.id
      if (enabled.id === "all") {
        this.enabled = [true, false]
      }
      this.load()
    },
    error() {
      this.$emit("clearAction");
    },
  },
};
</script>

<style lang="scss">
</style>
